import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";

export default function TopicDetailsMetadataTable(props) {
    const [metadata, setMetadata] = useState({});

    useEffect(() => {
        setMetadata(props.metadata)
    }, [props.metadata]);

    return (
        <div>
            {metadata ?
                <div>
                    <Typography variant="h4" component="h2">
                        MATERIA: {metadata.topicName} ({metadata.topicId ? metadata.topicId.split('#')[0] : <></>})
                    </Typography>
                    <Typography variant="h5" component="h2">
                        {metadata.groupMajor} - {metadata.groupGroup}
                    </Typography>
                </div>
                : <></>}
        </div>
    );

}