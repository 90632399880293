import React, {useEffect, useState} from "react";
import "../Stylesheets/Home.css";
import {API, Auth} from "aws-amplify";
import MaterialTable from "material-table";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import CustomizedSnackbars from "../../components/CommonUtilities/CustomizedSnackbars";
import {LOCALIZATION} from "../../components/Material-TableCustomization/Localization/LocalizationES";
import {REMOVE_DROP_SHADOW} from "../../components/Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {HOME_COLUMN_DEFINITION} from "../../components/Material-TableCustomization/ColumnsDefinition/HomeColumnDefinition";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../components/Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";

export default function Home(props) {

    const [professor, setProfessor] = useState({});
    const [topics, setTopics] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [alertStatus, setAlertStatus] = useState({open: false, message: "", severity: ""});

    const loadTopicsApi = (professorId) => {
        return API.get('misServiciosEscolares', `/topics?professorId=${professorId}`);
    };

    const getTopicsForProfessor = async () => {
        setIsLoading(true);
        try {
            let professorId;
            if (!props.match.params.id) {
                const responseProfessor = await Auth.currentUserInfo();
                setProfessor(responseProfessor);
                professorId = responseProfessor?.attributes?.sub;
            } else {
                professorId = props.match.params.id
            }
            const responseTopics = await loadTopicsApi(professorId);
            setTopics(responseTopics)
        } catch (e) {
            console.log(e);
            alert("No se pudo encontrar la información solicitada, por favor, intente de nuevo");
        } finally {
            setIsLoading(false);
        }
    }

    const getCurrentUserInfo = async () => {
        const user = await API.Auth.currentAuthenticatedUser();
        console.log(user);
    }

    useEffect(() => {
        if (!props.isAuthenticated) {
            return;
        }
        getTopicsForProfessor();
        getCurrentUserInfo();
    }, [props.isAuthenticated, props.match.params.id]);

    const handleChangeOnAlert = (shouldPresentAlert) => {
        setAlertStatus(shouldPresentAlert);
    };

    const renderTeachers = () => {
        return (
            <div className="Professors">
                <MaterialTable
                    columns={HOME_COLUMN_DEFINITION(props)}
                    data={topics}
                    title={professor ? `Bienvenido ${professor?.attributes?.name}!` : "Materias Asignadas"}
                    isLoading={isLoading}
                    components={REMOVE_DROP_SHADOW}
                    icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    localization={LOCALIZATION('materia', 'materias')}
                    options={{
                        search: true,
                        pageSize: 10,
                        filtering: true,
                    }}
                />
            </div>
        );
    }

    return (
        <div className="Professors">
            <CustomizedSnackbars status={alertStatus} alertCallback={handleChangeOnAlert}/>
            {props.isAuthenticated ? renderTeachers() : props.history.push("/login")}
        </div>
    );
};