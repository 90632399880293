export const calculateCurrentCuatrimestreYYYYMMDD = () => {
    return `${new Date().getFullYear()}-0${4 * (Math.ceil(parseFloat((new Date().getMonth() + 1) / 4)) - 1) + 1}-01`;
}

export const verifyPayments = status => {
    return (status === 'MOROSO' || status === "BAJATEMPORAL" || status === "BAJADEFINITIVA");
};

export const K_ALERT = {
    SEVERITY: {
        SUCCESS: 'success',
        ERROR: 'error'
    },
    MESSAGE: {
        ERROR: 'Por favor intente de nuevo',
        GROUPS: {
            GROUP_DELETE: "El grupo ha sido dado de baja del sistema exitosamente",
            GROUP_UPDATE: "Los datos del grupo han sido actualizado exitosamente",
            GROUP_CREATE: "El grupo ha sido dado de creado exitosamente",
            GROUP_ADD_USER_TO_GROUP: "El estudiante ha sido añadido al grupo exitosamente",
            GROUP_DELETE_USER_FROM_GROUP: "El estudiante ha sido removido al grupo exitosamente",
        },
        STUDENTS: {
            STUDENT_DELETE: "El estudiante ha sido dado de baja del sistema exitosamente",
            STUDENT_UPDATE: "Los datos del estudiante han sido actualizado exitosamente",
            STUDENT_CREATE: "El estudiante ha sido dado de alta exitosamente"
        },
        PROFESSORS: {
            PROFESSOR_DELETE: "El profesor ha sido dado de baja del sistema exitosamente",
            PROFESSOR_UPDATE: "Los datos del profesor han sido actualizado exitosamente",
            PROFESSOR_CREATE: "El profesor ha sido dado de alta exitosamente"
        },
        TOPICS: {
            TOPICS_UPDATE_PROFESSOR: "El profesor ha sido vinculado exitosamente",
            TOPICS_UPDATE_SCHEDULE: "El horario ha sido actualizado exitosamente",
        },
        GRADES: {
            GRADES_UPDATED: "Las calificaciones han sido guardades exitosamente",
        }
    }
};

export const openAlert = (alertFunction, message = K_ALERT.MESSAGE.ERROR, severity = K_ALERT.SEVERITY.ERROR) => {
    alertFunction({
        open: true,
        message: message,
        severity: severity
    });
};

export const getListOfCyclesWithObjects = (lookupObject = false) => {
    const currentYear = new Date().getFullYear();
    const currentYearCycle = Math.ceil(parseFloat((new Date().getMonth()) / 4));
    let cyclesObject = [];
    for (let i = -2; i <= 2; i++) {
        const cycle = (currentYearCycle + i) % 3
        const yearGap = currentYearCycle + i <= 0 ? -1 : (currentYearCycle + i) === 3 ? 0 : parseInt((currentYearCycle + i) / 3);
        let cycleForObject = `${currentYear + yearGap}-0${cycle === 0 ? 3 : cycle}`
        cyclesObject[cycleForObject] = (cycleForObject === `${currentYear}-0${currentYearCycle}`);
    }
    return lookupObject ? Object.keys(cyclesObject).map(cycle => {return {[cycle]: cycle}}) : cyclesObject;
}

/**
 *
 * @returns "2020-02" YYYY-0X, where X is the cycle
 */
export const getCurrentCycle = () => {
    return `${new Date().getFullYear()}-0${Math.ceil(parseFloat(new Date().getMonth() / 4))}`;
};


export const majors = () => {
    return {
        "ENFERMERIA": "Enfermería",
        "PSICOPEDAGOGIA": "Psicopedagogía",
        "INDUSTRIAL": "Industrial",
        "ADMINISTRACION": "Administración",
        "PETROLERA": "Petrolero",
        "DERECHO": "Derecho",
        "AMBIENTAL": "Ambiental",
        "MAESTRIA PETROLERA": "Maestría Petrolera",
        "MAESTRIA EN EDUCACION": "Maestría en Educación",
    };
};

export const statuses = () => {
    return {
        "ACTIVO": "Activo",
        "INACTIVO": "Inactivo",
        "BAJATEMPORAL": "Baja Temporal",
        "BAJADEFINITIVA": "Baja Definitiva",
        "MOROSO": "Moroso",
        "TITULADO": "Titulado",
        "EGRESADO": "Egresado",
        "REINGRESO": "Reingreso",

    }
};

export const randomString = (strLength) => {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for ( let i = 0; i < strLength; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export const formatPhoneNumber = (phone) => {
    let cleaned = ('' + phone).replace(/\D/g, '')
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        return '(' + match[1] + ') ' + match[2] + ' ' + match[3]
    }
    return phone;
}

