import "./App.css";
import Routes from "./Routes";
import config from "./config";
import {Auth} from "aws-amplify";
import {API} from "aws-amplify";
import {withRouter} from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import React, {useState, useEffect} from "react";
import {LinkContainer} from "react-router-bootstrap";
import {Nav, Navbar, Form} from "react-bootstrap";
import UserContext from "./context/UserContext";

function App(props) {
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [userAttributes, setUserAttributes] = useState({});
    const [username, setUsername] = useState('');
    const [metadata, setMetadata] = useState({});

    useEffect(() => {
        onLoad();
    }, []);

    async function onLoad() {
        try {
            await Auth.currentSession();
            userHasAuthenticated(true);

            const response = await Auth.currentUserInfo();
            setUserAttributes(response.attributes);
            setUsername(response.username);
            const metadataResponse = await API.get("misServiciosEscolares", `/students/${response?.attributes?.sub}`);
            setMetadata(metadataResponse);

        } catch (e) {
            if (e !== 'No current user') {
                // TODO: Modal
            }
        }
        setIsAuthenticating(false);
    }

    async function handleLogout() {
        await Auth.signOut();
        userHasAuthenticated(false);
        props.history.push("/login");
    }

    return (
        !isAuthenticating &&
        <div className="App container">
            <UserContext.Provider value={{userAttributes, metadata}}>
                <Navbar bg="translucent" expand="lg">
                    <Navbar.Brand onClick={() => props.history.push("/")}>
                        <Tooltip title={config.env}>
                            <span>Portal Profesores</span>
                        </Tooltip>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            {isAuthenticated ? <Nav.Link onClick={() => props.history.push("/")}>Mis Grupos</Nav.Link> : <></>}
                        </Nav>
                        <Form inline>
                            <Navbar.Collapse>
                                <Nav className="ml-auto">
                                    {isAuthenticated
                                        ? <Nav.Link onClick={handleLogout}>Cerrar Sesión</Nav.Link>
                                        : <>
                                            <LinkContainer to="/signup">
                                                <Nav.Link>Crear cuenta</Nav.Link>
                                            </LinkContainer>
                                            <LinkContainer to="/login">
                                                <Nav.Link>Entrar</Nav.Link>
                                            </LinkContainer>
                                        </>
                                    }
                                </Nav>
                            </Navbar.Collapse>
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
                <Routes appProps={{isAuthenticated, userHasAuthenticated, userAttributes, username}}/>
            </UserContext.Provider>
        </div>
    );
}

export default withRouter(App);