import React from "react";
import {Route, Switch} from "react-router-dom";
import Home from "./containers/Containers/Home";
import Login from "./containers/Containers/Login";
import Signup from "./containers/Containers/Signup";
import AppliedRoute from "./components/AppliedRoute";
import NotFound from "./containers/Containers/NotFound";
import ResetPassword from "./containers/Containers/ResetPassword";
import SubjectDetails from "./containers/Containers/TopicDetails";

export default function Routes({appProps}) {
    return (
        <Switch>
            <AppliedRoute path="/" exact component={Home} appProps={appProps}/>
            <AppliedRoute path="/login" exact component={Login} appProps={appProps}/>
            <AppliedRoute path="/reset" exact component={ResetPassword} appProps={appProps}/>
            <AppliedRoute path="/signup" exact component={Signup} appProps={appProps}/>
            <AppliedRoute path="/simulate-professor/:id" exact component={Home} appProps={appProps}/>
            <AppliedRoute path="/simulate/:id" exact component={Home} appProps={appProps}/>
            <AppliedRoute path="/topics/:id" exact component={SubjectDetails} appProps={appProps} />
            <Route component={NotFound}/>
        </Switch>
    );
};
