import {Auth} from "aws-amplify";
import "../Stylesheets/Login.css";
import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Backdrop from "@material-ui/core/Backdrop";
import {amplifyConfig} from "../../AmplifyConfig";
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CircularProgress from "@material-ui/core/CircularProgress";

function Copyright() {
  return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://material-ui.com/">
          Mis Servicios Escolares
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


export default function Login(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState({email: "", password: ""});

  async function handleSubmit(event) {
    setIsLoading(true);
    event.preventDefault();

    try {
      Auth.configure(amplifyConfig);
      await Auth.signIn(fields.email, fields.password);
      props.userHasAuthenticated(true);
      props.history.push("/");
      setIsLoading(false);
    } catch (e) {
      console.error(`Error trying to logging in: ${e}`);
      if (e.code === 'NotAuthorizedException') {
        alert('Tu email/password son incorrectos, por favor corrige e intenta de nuevo.');
      } else if (e.code === 'UserNotFoundException') {
        alert('Tu email no existe en el sistema. Por favor crea una cuenta para poder ingresar al portal.');
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
      <div className="Login">
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit"/>
        </Backdrop>
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5" style={{color: '#333333'}}>
              Entrar a tu Cuenta
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Dirección Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(event) => setFields({...fields, email: event.target.value})}
              />
              <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(event) => setFields({...fields, password: event.target.value})}
              />
              <Button
                  // type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleSubmit}
                  disabled={fields.email === "" || fields.password === ""}
              >
                Entrar
              </Button>
            </form>
            <Link component="button" onClick={() => props.history.push("/reset")}>
              Olvidé mi Contraseña
            </Link>
          </div>
          <Box mt={8}>
            <Copyright/>
          </Box>
        </Container>
      </div>
  );
};
