import {API} from "aws-amplify";
import "../Stylesheets/TopicDetails.css"
import React, {useState, useEffect} from "react";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import {K_ALERT, openAlert} from "../../components/CommonUtilities/UtilityFunctions";
import CustomizedBackdrop from "../../components/CommonUtilities/CustomizedBackdrop";
import CustomizedSnackbars from "../../components/CommonUtilities/CustomizedSnackbars";
import TopicDetailsMetadataTable from "../../components/Details/Topic/TopicDetailsMetadataTable";
import TopicDetailsTabsContainer from "../../components/Details/Topic/TopicDetailsTabsContainer";

export default function GroupDetails(props) {
    const [alertStatus, setAlertStatus] = useState({open: false, message: "", severity: ""});
    const [attendance, setAttendance] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [metadata, setMetadata] = useState(null);
    const [grades, setGrades] = useState(null);

    const loadTopicApi = () => {
        const path = `/topics/${props.match.params.id}${props.location.search}`;
        return API.get("misServiciosEscolares", path);
    }

    const loadTopic = async () => {
        setIsLoading(true);
        try {
            const response = await loadTopicApi();
            setGrades(response.topicGroupGrades);
            setMetadata(response.topicGroupMetadata);
            setAttendance(response.topicGroupAttendance);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdate = (updateInProgress, error) => {
        console.log('handle update');
        if (updateInProgress) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
            if (error) {
                openAlert(setAlertStatus);
            } else {
                loadTopic().finally(() => console.log('Topics loaded successfully'));
                openAlert(setAlertStatus, K_ALERT.MESSAGE.GRADES.GRADES_UPDATED, K_ALERT.SEVERITY.SUCCESS);
            }
        }
    };

    const handleChangeOnAlert = (status) => {
        setAlertStatus(status);
    }

    useEffect(() => {
        loadTopic().finally('Finished useEffect');
    }, [props.match.params.id, props.location.search]);

    function renderSubjects() {
        return (
            <div className="SubjectDetails">
                <div className="SubjectDetailsSection">
                    <TopicDetailsMetadataTable grades={grades} metadata={metadata} callback={handleUpdate}/>
                </div>
                <div>
                    <TopicDetailsTabsContainer
                        grades={grades}
                        topicId={props.match.params.id}
                        metadata={metadata}
                        attendance={attendance}
                        callback={handleUpdate}/>
                </div>
            </div>
        );
    }

    return (
        <div className="GroupDetails">
            <CustomizedSnackbars status={alertStatus} alertCallback={handleChangeOnAlert}/>
            <CustomizedBackdrop open={isLoading}/>
            {props.isAuthenticated ? renderSubjects() : props.history.push("/login")}
        </div>
    );
};