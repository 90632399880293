import React from "react";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Error";
import {green, red} from "@material-ui/core/colors";

export function HOME_COLUMN_DEFINITION(props) {
    return [
        {
            title: 'Clave',
            field: 'topicId',
            render: rowData =>
                <Button color='secondary'
                        onClick={() => props.history.push(`/topics/${rowData.topicId.split('#')[0]}?groupId=${rowData.groupId}`)}
                        component={Link}>
                    {rowData.topicId.split('#')[0]}
                </Button>
        },
        {
            title: 'Materia',
            field: 'topicName',
        },
        {
            title: 'Carrera',
            field: 'groupMajor',
            render: rowData => <>{rowData.major}-{rowData.cuatrimestre}{rowData.group}</>
        },
        {
            title: 'Modo',
            field: 'mode',
        },
        {
            title: 'Ciclo',
            field: 'startingCycle'
        },
        {
            title: 'Entregado',
            field: 'topicHasBeenGraded',
            render: rowData => <span style={{ textAlign: "center" }}>{rowData.topicHasBeenGraded ?
                <CheckCircleIcon style={{ color: green[500] }}/> :
                <CancelIcon style={{ color: red[500] }}/>
            }</span>
        },
    ];
}